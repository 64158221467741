/* appLoader */
.effect-1,
.effect-2,
.effect-3 {
  border-left: 3px solid #FA5000 !important;
}
/* activeColor */
a.active {
  /* background: linear-gradient(118deg, #1390f8, #1390f8) !important; */
}
 
.rdt_TableHeadRow {
  white-space: pre-line !important;
  overflow: inherit !important;
  text-align: center !important;
}
.custom-table { padding-bottom: 70px !important;}
.custom-hover:hover { cursor: pointer;}
.rdt_TableCol .rdt_TableCol_Sortable div { white-space: pre-line !important; }

.OnlyCapitalized {
  text-transform: capitalize !important;
}
.text-justify { text-align: justify;}
.pro-banner img { width: 100%; border-radius: 8px; }
.panel-frequency {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  display: inline;
  padding: 6px 15px;
  width: auto;
  float: left;
  text-align: center;
  margin: 0px 8px;
  border-radius: 6px;
  cursor: pointer;
  background: var(--base-white, #fa5000);

  /* Shadow/sm */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}

.panel-frequency p span{ font-weight: 400; font-size: 14px;}

/* Modal backdrop background color */
.modal-backdrop{
  background-color: #555 !important;
}